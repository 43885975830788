import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IconsRegistry, UiService } from '@whbabtec-services';
import { completeIconSet } from '@whbabtec-types';

@Component({
  selector: 'whbabtec-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private iconRegistry: IconsRegistry,
    private uiService: UiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.iconRegistry.registerIcons(completeIconSet);
    this.uiService.uiState$.subscribe((state) => {
      if (state.isMaintenanceModeActive) {
        //this.router.navigate(['maintenance']);
      }
    });
  }
}
