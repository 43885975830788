import { Injectable } from '@angular/core';
import { WhbabtecLanguages } from '@whbabtec-types';
import { WHBABTEC_LABELS } from '../labels';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  constructor() {}

  public getByKey(key: string, lang: WhbabtecLanguages): string {
    if (!WHBABTEC_LABELS[lang][key]) {
      return key;
    }

    return WHBABTEC_LABELS[lang][key];
  }
}
