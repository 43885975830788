type WhbabtecUserInformation = {
  areaManager: string;
  companyName: string;
  creationDate: string;
  languageID: number;
  salutation: string;
  status: string;
  couAccepted: number;
  couAcceptedRenew: number;
  country: string;
  email: string;
  firstname: string;
  idUser: number;
  lastname: string;
  login: string;
  newsletter: string;
  password: null | string;
  oemobjects: number;
};

export type WhbabtecUser = WhbabtecUserInformation & {
  userInformation: WhbabtecUserInformation;
  businessPartnerNumber: string;
};

export enum WHBABTEC_STORAGE {
  KEEP_LOGGED_IN = 'whbabtec_keepLoggedIn',
  ACCESS_TOKEN = 'whbabtec_accessToken',
  REFRESH_TOKEN = 'whbabtec_refreshToken',
  LANGUAGE = 'whbabtec_language',
}
