import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { UserService } from '@whbabtec-services';
import { WHBABTEC_ENDPOINTS } from '@whbabtec-types';
import { Observable, Subject, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SerialNumberValidatorService {
  private isValidationFinishedSubject = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) {}

  public isValidationFinished(): Observable<void> {
    return this.isValidationFinishedSubject.asObservable();
  }

  public onValidateSerialNumber(controlName: string): AsyncValidatorFn {
    return async (
      serialNumberControl: AbstractControl
    ): Promise<ValidationErrors | null> => {
      const formGroup = serialNumberControl.parent;
      const refNumber = formGroup?.get('refNumber');
      const productionDate = formGroup?.get('productionDate');

      const serialNumberValue = serialNumberControl.value;

      if (!refNumber || !productionDate || !serialNumberValue || !formGroup) {
        return null;
      }

      serialNumberControl.setErrors(null);

      const serialNumberControlsKeys = Object.keys(
        formGroup?.controls as UntypedFormGroup[]
      ).filter(
        (controlKey) =>
          controlKey.indexOf('serialNumber') !== -1 &&
          controlKey !== controlName
      );

      for (let i = 0; i < serialNumberControlsKeys.length; i++) {
        const snControl = formGroup?.get(serialNumberControlsKeys[i]);

        if (snControl?.value === serialNumberValue) {
          return { custom_label: 'error_serial_number_unique_validation' };
        }
      }

      try {
        const response = await lastValueFrom(
          this.httpClient.get(
            `${WHBABTEC_ENDPOINTS.PRODUCT}/${refNumber.value}/${serialNumberValue}`,
            {
              headers: {
                Authorization: `Bearer ${
                  this.userService.getAccessToken()?.token
                }`,
              },
            }
          )
        );

        if (response) {
          productionDate?.setValue((response as any).salesDateProduction);
          this.isValidationFinishedSubject.next();
          return null;
        }

        this.isValidationFinishedSubject.next();
        return { custom_label: 'error_serial_number_validation' };
      } catch (error) {
        this.isValidationFinishedSubject.next();
        return { custom_label: 'error_serial_number_validation' };
      }
    };
  }
}
