import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { WHBABTEC_LANGS } from '@whbabtec-types';

@Injectable({
  providedIn: 'root',
})
export class LanguageGuard {
  constructor(private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    if (WHBABTEC_LANGS.some((lang) => lang === route.params['lang'])) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
