import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@whbabtec-services';
import { WHBABTEC_MAINTENANCE_MODE_ACTIVE } from '@whbabtec-types';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard {
  constructor(private router: Router, private userService: UserService) {}

  public canActivate(): boolean {
    if (!WHBABTEC_MAINTENANCE_MODE_ACTIVE) {
      this.router.navigate([`/${this.userService.getLanguage()}/login`]);
    }

    return true;
  }
}
