import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';

export abstract class WhbabtecErrorable {
  protected abstract parentFormGroup?: FormGroup;
  protected abstract parentFormControlName?: string;

  constructor() {}

  protected getFormControl(): FormControl | undefined {
    if (!this.parentFormControlName) {
      return undefined;
    }
    return this.parentFormGroup?.get(this.parentFormControlName) as FormControl;
  }

  protected isTouched(): boolean {
    return !!this.getFormControl()?.touched;
  }

  public getErrors(): ValidationErrors | undefined {
    const formControl = this.getFormControl();

    if (formControl?.errors) {
      return formControl?.errors;
    }

    return undefined;
  }

  public shouldShowErrors(): boolean {
    return this.isTouched() && !!this.getErrors();
  }
}
