import { WhbabtecLanguages } from '@whbabtec-types';

type WhbabtecLabels = {
  [key in WhbabtecLanguages]: {
    [key: string]: string;
  };
};

export const WHBABTEC_LABELS: WhbabtecLabels = {
  de_global: {
    de_global: 'Deutsch',
    en_global: 'Englisch',
    es_global: 'Spanisch',
    ru_cis: 'Russisch',
    complaint: 'Reklamation',
    complaints: 'Reklamationen',
    complaints_plattform: 'auf der Reklamationsplattform',
    login: 'Anmelden',
    logout: 'Abmelden',
    username: 'Benutzername',
    password: 'Passwort',
    keep_logged_in: 'Eingeloggt bleiben',
    password_forgot: 'Passwort vergessen',
    error_mandatory_field: 'Dieses Feld ist ein Pflichtfeld.',
    error_invalid_email_field: 'Gültige E-Mail Adresse eingeben.',
    error_wrong_login_credentials:
      'Benutzername oder Passwort falsch. Bitte erneut versuchen.',
    register: 'Zur Registrierung',
    imprint: 'Impressum',
    imprint_link: 'https://imp.wh.com/partnernet/de_global/imprint/',
    privacy: 'Datenschutz',
    privacy_link: 'https://imp.wh.com/partnernet/de_global/imprint/',
    terms: 'AGB',
    terms_link: 'https://imp.wh.com/partnernet/de_global/legal_information/',
    hello: 'Hallo',
    new_complaint: 'Neue Reklamation',
    user_data: 'Anwenderdaten',
    file_upload: 'Datei-Upload',
    country: 'Land',
    technical_complaint: 'Technische Reklamation',
    administrative_complaint: 'Administrative/logistische Reklamation',
    upload_filesize_error:
      'Eine oder mehrere Dateien sind für den Upload zu groß. Das Limit beträgt 15MB pro Datei.',
    yes: 'Ja',
    no: 'Nein',
    ref_number: 'REF Nummer (8-Stellig)',
    error_ref_number_validation:
      'Geben Sie bitte eine gültige REF Nummer ein! (8-stellig)',
    product_name: 'Produktbezeichnung',
    product_component: 'Produktkomponente',
    defect_component: 'Defekte Komponente',
    serial_number: 'Seriennummer',
    quantity: 'Menge',
    complaint_type: 'Reklamationsart',
    link_reporting_form_label: 'Zum Meldeformular',
    link_reporting_form:
      'https://imp.wh.com/en_global/partnernet/documentfinder/complaint_new/docs/Vorlage_1433-ADT.docx',
    invoice_number: 'Rechnungsnummer',
    is_injury_present: 'Wurde jemand verletzt?',
    complaint_description: 'Beanstandungsgrund',
    is_goods_return_requested: 'Wird Ware zurückgesendet?',
    goods_return_shipping_label: 'Zum Versandaufkleber',
    goods_return_shipping_label_link:
      'https://imp.wh.com/en_global/partnernet/documentfinder/complaint_new/Versandaufkleber.aspx',
    file_upload_description:
      'Laden Sie hier ihre Datein mit einer max. Größe von 15MB pro Datei hoch.',
    complaint_send: 'Reklamation absenden',
    back_to_overview: 'Zurück zur Übersicht',
    error_serial_number_validation:
      'Geben Sie bitte eine gültige Seriennummer ein!',
    error_serial_number_unique_validation:
      'Geben Sie bitte eine eindeutige Seriennummer ein!',
    injury_description: 'Beschreibung des Vorfalls',
    reported_to_authority: 'An Behörde gemeldet',
    upload_video_foto_for_return_information:
      'Bitte Laden Sie durch den unten angeführten Upload Button ein Foto oder Video hoch.',
    reported_to_authority_description: 'An welche Behörde wurde gemeldet?',
    complaint_submit_error:
      'Es ist ein Fehler beim Absenden aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an <a href="mailto:complaints@wh.com" class="text-primary-500">complaints@wh.com</a>.',
    complaint_submit_success:
      'Vielen Dank für Ihre Rückmeldung. Die Reklamation ist eingegangen. Sie können den aktuellen Status Ihrer Reklamationen in der Reklamationsübersicht abrufen.',
    id: 'ID',
    received_on: 'Erhalten am',
    status: 'Status',
    ref: 'REF',
    product: 'Produkt',
    error_description: 'Problembeschreibung',
    final_documentation: 'Abschlussdokumentation',
    status_received: 'Eingegangen',
    status_first_evaluation: 'Erstbewertung',
    status_investigation: 'Untersuchung',
    status_waiting_for_return: 'Warten auf Rücksendung',
    status_completed: 'Abgeschlossen',
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'E-Mail',
    business_partner_number: 'Handelspartner-Nummer',
    company_name: 'Handelspartner-Name',
    return_shipment_info:
      'Bitte warten Sie mit dem Einsenden der Ware bis wir die Reklamation intern bewertet haben. Sie bekommen in kürze eine Rückmeldung.',
    select_files: 'Datei(n) auswählen',
    search: 'Suche',
    add_serial_number: 'Seriennummer hinzufügen (max. 30)',
    add_product: 'Weiteres Produkt hinzufügen',
    status_selection: 'Status',
    complaintNumber_selection: 'Reklamationsart',
    table_filter_no_results:
      'Es tut uns leid, aber Ihre Suche oder Filterung hat keine Treffer ergeben. Bitte überprüfen Sie Ihre Suchkriterien und versuchen Sie es erneut.',
    technical: 'Technisch',
    administrative: 'Administrativ',
    remove_product: 'Produkt entfernen',
    remove_serial_number: 'Seriennummer entfernen',
    training_material: 'Anleitung.pdf',
    complaints_load_error:
      'Beim Laden der Reklamationen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an <a href="mailto:complaints@wh.com" class="text-primary-500">complaints@wh.com</a>.',
    maintenance_headline: 'Wartungsarbeiten',
    maintenance_description:
      'Wir führen aktuell Wartungsarbeiten durch. Bitte versuchen Sie es später erneut.',
  },
  en_global: {
    de_global: 'German',
    en_global: 'English',
    es_global: 'Spanish',
    ru_cis: 'Russian',
    complaint: 'Complaint',
    complaints: 'Complaints',
    complaints_plattform: 'on the complaints platform',
    login: 'Login',
    logout: 'Logout',
    username: 'Username',
    password: 'Password',
    keep_logged_in: 'Keep me logged in',
    password_forgot: 'Forgot password',
    error_mandatory_field: 'This field is a mandatory field.',
    error_invalid_email_field: 'Valid email address required.',
    error_wrong_login_credentials:
      'Username or password is invalid. Please try again.',
    register: 'Register',
    imprint: 'Imprint',
    imprint_link: 'https://imp.wh.com/partnernet/en_global/imprint/',
    privacy: 'Privacy',
    privacy_link: 'https://imp.wh.com/partnernet/en_global/imprint/',
    terms: 'Terms',
    terms_link: 'https://imp.wh.com/partnernet/en_global/legal_information/',
    hello: 'Hello',
    new_complaint: 'New complaint',
    user_data: 'User data',
    file_upload: 'File upload',
    country: 'Country',
    technical_complaint: 'Technical complaint',
    administrative_complaint: 'Administrative/logistic complaint',
    upload_filesize_error:
      'One or more files are too large for upload. The limit is 15MB per file.',
    yes: 'Yes',
    no: 'No',
    ref_number: 'REF Number (8 digits)',
    error_ref_number_validation: 'Please enter a valid REF number! (8 digits)',
    product_name: 'Product name',
    product_component: 'Product component',
    defect_component: 'Defective component',
    serial_number: 'Serial number',
    quantity: 'Quantity',
    complaint_type: 'Complaint type',
    link_reporting_form_label: 'reporting form',
    link_reporting_form:
      'https://imp.wh.com/en_global/partnernet/documentfinder/complaint_new/docs/Vorlage_1433-ADT.docx',
    invoice_number: 'Invoice number',
    is_injury_present: 'Was anyone injured?',
    complaint_description: 'Reason for complaint',
    is_goods_return_requested: 'Are goods returned?',
    goods_return_shipping_label: 'Download Shipping label',
    goods_return_shipping_label_link:
      'https://imp.wh.com/en_global/partnernet/documentfinder/complaint_new/Versandaufkleber.aspx',
    file_upload_description:
      'Upload your files here with a max. size of 15MB per file.',
    complaint_send: 'Send complaint',
    back_to_overview: 'Back to overview',
    error_serial_number_validation: 'Please enter a valid serial number!',
    error_serial_number_unique_validation:
      'Please enter a unique serial number!',
    injury_description: 'Description of the incident',
    reported_to_authority: 'Reported to authority',
    upload_video_foto_for_return_information:
      'Please upload a photo or video using the upload button below.',
    reported_to_authority_description: 'Which authority was reported to?',
    complaint_submit_error:
      'An error occurred while submitting. Please try again or contact <a href="mailto:complaints@wh.com" class="text-primary-500">complaints@wh.com</a>.',
    complaint_submit_success:
      'Thank you for your response. We have received your complaint. You can check the status of your complaints at the overview.',
    id: 'ID',
    received_on: 'Received on',
    status: 'Status',
    ref: 'REF',
    product: 'Product',
    error_description: 'Problem description',
    final_documentation: 'Final documentation',
    status_received: 'Received',
    status_first_evaluation: 'First evaluation',
    status_investigation: 'Investigation',
    status_waiting_for_return: 'Waiting for return of goods',
    status_completed: 'Completed',
    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'E-Mail',
    business_partner_number: 'Business partner number',
    company_name: 'Business partner name',
    return_shipment_info:
      'Please hold back the return shipment until we have made the initial evaluation. We will be in touch.',
    select_files: 'Select file(s)',
    search: 'Search',
    add_serial_number: 'Add serial number (max. 30)',
    add_product: 'Add another product',
    status_selection: 'Status',
    complaintNumber_selection: 'Complaint type',
    table_filter_no_results:
      'We are sorry, but your search or filtering did not yield any results. Please check your search criteria and try again.',
    technical: 'Technical',
    administrative: 'Administrative',
    remove_product: 'Remove product',
    remove_serial_number: 'Remove serial number',
    training_material: 'Instructions.pdf',
    complaints_load_error:
      'An error occurred while loading complaints. Please try again or contact <a href="mailto:complaints@wh.com" class="text-primary-500">complaints@wh.com</a>.',
    maintenance_headline: 'Maintenance',
    maintenance_description:
      'We are currently performing maintenance. Please try again later.',
  },
  es_global: {
    de_global: 'Alemán',
    en_global: 'Inglés',
    es_global: 'Español',
    complaint: 'Queja',
    complaints: 'Quejas',
    complaints_plattform: 'en la plataforma de quejas',
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    keep_logged_in: 'Mantenerme conectado',
    password_forgot: 'Contraseña olvidada',
    error_mandatory_field: 'Este campo es un campo obligatorio.',
    error_invalid_email_field:
      'Introduzca una dirección de correo electrónico válida.',
    error_wrong_login_credentials:
      'Nombre de usuario o contraseña no válidos. Por favor, inténtelo de nuevo.',
    register: 'Registrarse',
    ru_cis: 'Ruso',
    imprint: 'Imprimir',
    imprint_link: 'https://imp.wh.com/partnernet/es_global/imprint/',
    privacy: 'Intimidad',
    privacy_link: 'https://imp.wh.com/partnernet/es_global/imprint/',
    terms: 'Términos',
    terms_link: 'https://imp.wh.com/partnernet/es_global/legal_information/',
    hello: 'Hola',
    new_complaint: 'Nueva queja',
    user_data: 'Datos de usuario',
    file_upload: 'Subida de archivos',
    country: 'País',
    technical_complaint: 'Queja técnica',
    administrative_complaint: 'Queja administrativa/logística',
    upload_filesize_error:
      'Uno o más archivos son demasiado grandes para la carga. El límite es de 15MB por archivo.',
    yes: 'Sí',
    no: 'No',
    ref_number: 'REF Número (8 dígitos)',
    error_ref_number_validation:
      '¡Por favor, introduzca un número REF válido! (8 dígitos)',
    product_name: 'Nombre del producto',
    product_component: 'Componente del producto',
    defect_component: 'Componente defectuoso',
    serial_number: 'Número de serie',
    quantity: 'Cantidad',
    complaint_type: 'Tipo de queja',
    link_reporting_form_label: 'formulario de informe',
    link_reporting_form:
      'https://imp.wh.com/en_global/partnernet/documentfinder/complaint_new/docs/Vorlage_1433-ADT.docx',
    invoice_number: 'Número de factura',
    is_injury_present: '¿Alguien resultó herido?',
    complaint_description: 'Motivo de la queja',
    is_goods_return_requested: '¿Se solicitan devoluciones de mercancías?',
    goods_return_shipping_label: 'Descargar etiqueta de envío',
    goods_return_shipping_label_link:
      'https://imp.wh.com/en_global/partnernet/documentfinder/complaint_new/Versandaufkleber.aspx',
    file_upload_description:
      'Cargue sus archivos aquí con un tamaño máximo de 15MB por archivo.',
    complaint_send: 'Enviar queja',
    back_to_overview: 'Volver a la visión general',
    error_serial_number_validation:
      '¡Por favor, introduzca un número de serie válido!',
    error_serial_number_unique_validation:
      '¡Por favor, introduzca un número de serie único!',
    injury_description: 'Descripción del incidente',
    reported_to_authority: 'Reportado a la autoridad',
    upload_video_foto_for_return_information:
      'Suba una foto o video usando el botón de carga a continuación.',
    reported_to_authority_description: '¿A qué autoridad se informó?',
    complaint_submit_error:
      'Se produjo un error al enviar. Por favor, inténtelo de nuevo o póngase en contacto con <a href="mailto:complaints@wh.com" class="text-primary-500">complaints@wh.com</a>.',
    complaint_submit_success:
      'Gracias por su respuesta. Hemos recibido su queja. Puede comprobar el estado de sus quejas en la visión general.',
    id: 'ID',
    received_on: 'Recibido el',
    status: 'Estado',
    ref: 'REF',
    product: 'Producto',
    error_description: 'Descripción del problema',
    final_documentation: 'Documentación final',
    status_received: 'Recibido',
    status_first_evaluation: 'Primera evaluación',
    status_investigation: 'Investigación',
    status_waiting_for_return: 'Esperando devolución de mercancías',
    status_completed: 'Completado',
    firstname: 'Nombre de pila',
    lastname: 'Apellido',
    email: 'E-Mail',
    business_partner_number: 'Número de socio comercial',
    company_name: 'Nombre del socio comercial',
    return_shipment_info:
      'Por favor, espere con el remitente de la mercancía hasta que hayamos evaluado la queja internamente. Le daremos una respuesta en breve.',
    select_files: 'Seleccionar archivo(s)',
    search: 'Buscar',
    add_serial_number: 'Añadir número de serie (máx. 30)',
    add_product: 'Añadir otro producto',
    status_selection: 'Estado',
    complaintNumber_selection: 'Tipo de queja',
    table_filter_no_results:
      'Lo sentimos, pero su búsqueda o filtrado no ha dado ningún resultado. Por favor, compruebe sus criterios de búsqueda e inténtelo de nuevo.',
    technical: 'Técnico',
    administrative: 'Administrativo',
    remove_product: 'Eliminar producto',
    remove_serial_number: 'Eliminar número de serie',
    training_material: 'Instructions.pdf',
    complaints_load_error:
      'Se produjo un error al cargar las quejas. Por favor, inténtelo de nuevo o póngase en contacto con <a href="mailto:complaints@wh.com" class="text-primary-500">complaints@wh.com</a>.',
    maintenance_headline: 'Mantenimiento',
    maintenance_description:
      'Actualmente estamos realizando trabajos de mantenimiento. Por favor, inténtelo de nuevo más tarde',
  },
  ru_cis: {
    de_global: 'Немецкий',
    en_global: 'Английский',
    es_global: 'Испанский',
    complaint: 'Жалоба',
    complaints: 'Жалобы',
    complaints_plattform: 'на платформе жалоб',
    login: 'Авторизоваться',
    logout: 'Выйти',
    username: 'Имя пользователя',
    password: 'Пароль',
    keep_logged_in: 'Оставайтесь в системе',
    password_forgot: 'Забыли пароль',
    error_mandatory_field: 'Это поле является обязательным для заполнения.',
    error_invalid_email_field: 'Требуется действующий адрес электронной почты.',
    error_wrong_login_credentials:
      'Неверное имя пользователя или пароль. Пожалуйста, попробуйте еще раз.',
    register: 'Регистрация',
    ru_cis: 'Русский',
    imprint: 'Отпечаток',
    imprint_link: 'https://imp.wh.com/partnernet/ru_cis/imprint/',
    privacy: 'Конфиденциальность',
    privacy_link: 'https://imp.wh.com/partnernet/ru_cis/imprint/',
    terms: 'Условия',
    terms_link: 'https://imp.wh.com/partnernet/ru_cis/legal_information/',
    hello: 'Привет',
    new_complaint: 'Новая жалоба',
    user_data: 'Данные пользователя',
    file_upload: 'Загрузка файлов',
    country: 'Страна',
    technical_complaint: 'Техническая жалоба',
    administrative_complaint: 'Административная/логистическая жалоба',
    upload_filesize_error:
      'Один или несколько файлов слишком велики для загрузки. Лимит составляет 15 МБ на файл.',
    yes: 'Да',
    no: 'Нет',
    ref_number: 'REF Номер (8 цифр)',
    error_ref_number_validation:
      'Пожалуйста, введите действительный номер REF! (8 цифр)',
    product_name: 'Название продукта',
    product_component: 'Компонент продукта',
    defect_component: 'Дефектный компонент',
    serial_number: 'Серийный номер',
    quantity: 'Количество',
    complaint_type: 'Тип жалобы',
    link_reporting_form_label: 'форма отчетности',
    link_reporting_form:
      'https://imp.wh.com/en_global/partnernet/documentfinder/complaint_new/docs/Vorlage_1433-ADT.docx',
    invoice_number: 'Номер счета',
    is_injury_present: 'Кто-то пострадал?',
    complaint_description: 'Причина жалобы',
    is_goods_return_requested: 'Требуется возврат товара?',
    goods_return_shipping_label: 'Загрузить этикетку доставки',
    goods_return_shipping_label_link:
      'https://imp.wh.com/en_global/partnernet/documentfinder/complaint_new/Versandaufkleber.aspx',
    file_upload_description:
      'Загрузите ваши файлы здесь с максимальным размером 15 МБ на файл.',
    complaint_send: 'Отправить жалобу',
    back_to_overview: 'Вернуться к обзору',
    error_serial_number_validation:
      'Пожалуйста, введите действительный серийный номер!',
    error_serial_number_unique_validation:
      'Пожалуйста, введите уникальный серийный номер!',
    injury_description: 'Описание инцидента',
    reported_to_authority: 'Сообщено властям',
    upload_video_foto_for_return_information:
      'Загрузите фото или видео, используя кнопку загрузки ниже.',
    reported_to_authority_description: 'Какие власти были уведомлены?',
    complaint_submit_error:
      'Ошибка при отправке. Пожалуйста, попробуйте еще раз или свяжитесь с <a href="mailto:complaints@wh.com" class="text-primary-500">complaints@wh.com</a>.',
    complaint_submit_success:
      'Спасибо за ваш ответ. Мы получили вашу жалобу. Вы можете проверить статус ваших жалоб в обзоре.',
    id: 'ID',
    received_on: 'Получено',
    status: 'Статус',
    ref: 'REF',
    product: 'Продукт',
    error_description: 'Описание проблемы',
    final_documentation: 'Финальная документация',
    status_received: 'Получено',
    status_first_evaluation: 'Первая оценка',
    status_investigation: 'Расследование',
    status_waiting_for_return: 'Ожидание возврата товара',
    status_completed: 'Завершено',
    firstname: 'Имя',
    lastname: 'Фамилия',
    email: 'E-Mail',
    business_partner_number: 'Номер бизнес-партнера',
    company_name: 'Название бизнес-партнера',
    return_shipment_info: 'Пожалуйста, удерживайте возврат до нашей оценки.',
    select_files: 'Выбрать файл(ы)',
    search: 'Поиск',
    add_serial_number: 'Добавить серийный номер (макс. 30)',
    add_product: 'Добавить еще один продукт',
    status_selection: 'Статус',
    complaintNumber_selection: 'Тип жалобы',
    table_filter_no_results:
      'Извините, но ваш поиск или фильтрация не дали результатов. Пожалуйста, проверьте критерии поиска и попробуйте еще раз.',
    technical: 'Технический',
    administrative: 'Административный',
    remove_product: 'Удалить продукт',
    remove_serial_number: 'Удалить серийный номер',
    training_material: 'Instructions.pdf',
    complaints_load_error:
      'Ошибка при загрузке жалоб. Пожалуйста, попробуйте еще раз или свяжитесь с <a href="mailto:complaints@wh.com" class="text-primary-500">complaints@wh.com</a>.',
    maintenance_headline: 'Техническое обслуживание',
    maintenance_description:
      'Мы в настоящее время проводим техническое обслуживание. Пожалуйста, попробуйте позже.',
  },
};
