import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WHBABTEC_STORAGE } from '@whbabtec-types';
import { BehaviorSubject, Observable } from 'rxjs';
import { WhbabtecLanguages } from '../types/languages.type';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private currentLanguage$$ = new BehaviorSubject<
    WhbabtecLanguages | undefined
  >(undefined);

  constructor(private router: Router, private storageService: StorageService) {
    const lang =
      this.storageService.get<WhbabtecLanguages>(WHBABTEC_STORAGE.LANGUAGE) ||
      (this.router.url.split('/')[1] as WhbabtecLanguages);
    this.storeLanguage(lang);
    this.currentLanguage$$.next(lang);
  }

  get currentLanguage$(): Observable<WhbabtecLanguages | undefined> {
    return this.currentLanguage$$.asObservable();
  }

  public setLanguage(lang: WhbabtecLanguages): void {
    const navigationUrl = this.router.url
      .split('/')
      .map((slug) =>
        slug.replace(this.currentLanguage$$.getValue() as string, lang)
      )
      .join('/');

    this.storeLanguage(lang);

    this.currentLanguage$$.next(lang);
    this.router.navigate([navigationUrl]);
  }

  private storeLanguage(lang: WhbabtecLanguages): void {
    this.storageService.set<string>(WHBABTEC_STORAGE.LANGUAGE, lang);
  }
}
