/* 🤖 this file was generated by svg-to-ts */
export const iconAddFile: {
  name: 'add-file';
  data: string;
} = {
  name: 'add-file',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.2 2.4a2.4 2.4 0 0 0-2.4 2.4v6.248a6.575 6.575 0 0 1 1.2-.222V4.8a1.2 1.2 0 0 1 1.2-1.2H12v4.2a1.8 1.8 0 0 0 1.8 1.8H18v9.6a1.2 1.2 0 0 1-1.2 1.2h-4.32c-.218.427-.483.83-.788 1.2H16.8a2.4 2.4 0 0 0 2.4-2.4V8.897a1.8 1.8 0 0 0-.528-1.272l-4.697-4.698a1.8 1.8 0 0 0-1.272-.527H7.2Zm10.552 6H13.8a.6.6 0 0 1-.6-.6V3.848L17.752 8.4ZM12 17.4a5.4 5.4 0 1 1-10.8 0 5.4 5.4 0 0 1 10.8 0ZM7.2 15A.6.6 0 0 0 6 15v1.8H4.2a.6.6 0 1 0 0 1.2H6v1.8a.6.6 0 0 0 1.2 0V18H9a.6.6 0 1 0 0-1.2H7.2V15Z"/></svg>`
};
export const iconAdd: {
  name: 'add';
  data: string;
} = {
  name: 'add',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8 3a.5.5 0 0 1 .5.5v4h4a.5.5 0 0 1 0 1h-4v4a.5.5 0 0 1-1 0v-4h-4a.5.5 0 0 1 0-1h4v-4A.5.5 0 0 1 8 3Z"/></svg>`
};
export const iconAdministrative: {
  name: 'administrative';
  data: string;
} = {
  name: 'administrative',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.027 3.654C4.569 3.203 5.254 3 6 3h12c.715 0 1.397.187 1.946.628.55.442.895 1.081 1.04 1.851l1.498 7.116a.75.75 0 0 1 .016.155v5.252A3.007 3.007 0 0 1 19.502 21H4.498A3.007 3.007 0 0 1 1.5 18.002V12.75a.75.75 0 0 1 .016-.155l1.499-7.118c.143-.738.471-1.375 1.012-1.823Zm.958 1.153c-.22.183-.407.482-.498.96l-.003.013L3 12.828v5.17A1.506 1.506 0 0 0 4.501 19.5H19.5a1.506 1.506 0 0 0 1.5-1.501v-5.17l-1.484-7.05-.003-.017c-.091-.492-.285-.787-.506-.964-.225-.18-.55-.298-1.007-.298H6c-.473 0-.796.125-1.015.307Z"/><path d="M6 6.75A.75.75 0 0 1 6.75 6h10.5a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 6.75Zm-.75 3A.75.75 0 0 1 6 9h12a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Zm-3.75 3a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75 2.25 2.25 0 0 0 4.5 0A.75.75 0 0 1 15 12h6.75a.75.75 0 0 1 0 1.5h-6.076a3.749 3.749 0 0 1-7.348 0H2.25a.75.75 0 0 1-.75-.75Z"/></svg>`
};
export const iconArrowRight: {
  name: 'arrow-right';
  data: string;
} = {
  name: 'arrow-right',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.293 6.293a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414L17.586 13H4a1 1 0 1 1 0-2h13.586l-3.293-3.293a1 1 0 0 1 0-1.414Z"/></svg>`
};
export const iconAttention: {
  name: 'attention';
  data: string;
} = {
  name: 'attention',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 18.8c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5zm0-16.6c.8 0 1.5.7 1.5 1.5v10.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5V3.8c0-.9.7-1.6 1.5-1.6z"/></svg>`
};
export const iconChevronDown: {
  name: 'chevron-down';
  data: string;
} = {
  name: 'chevron-down',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708Z"/></svg>`
};
export const iconChevronRight: {
  name: 'chevron-right';
  data: string;
} = {
  name: 'chevron-right',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708Z"/></svg>`
};
export const iconEye: {
  name: 'eye';
  data: string;
} = {
  name: 'eye',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="m1.088 7.73-.41-.287-.001.002.411.284Zm-.008.554.421-.269-.001-.002-.42.271Zm13.84-.01-.42-.271-.001.001.421.27ZM15 8h-.5.5Zm-.08-.272.42-.271v-.001l-.42.271ZM7.99 3C5.347 3 2.711 4.528.677 7.443l.82.572C3.399 5.291 5.76 4 7.99 4V3ZM.676 7.445a1 1 0 0 0-.177.553l1 .015-.823-.568ZM.5 7.998a1 1 0 0 0 .16.558l.84-.543-1-.015Zm.159.556C2.229 11.013 4.834 13 7.989 13v-1c-2.71 0-5.036-1.713-6.488-3.985l-.842.539ZM7.989 13c3.123 0 5.778-1.99 7.353-4.457l-.843-.539C13.046 10.28 10.669 12 7.989 12v1Zm7.352-4.456c.104-.162.16-.35.16-.544h-1l-.001.003.84.541ZM15.5 8c0-.192-.056-.381-.16-.543l-.841.541V8h1Zm-.161-.544C13.764 5.02 11.091 3 7.99 3v1c2.656 0 5.052 1.747 6.51 4l.84-.544ZM10 8a2 2 0 0 1-2 2v1a3 3 0 0 0 3-3h-1Zm-2 2a2 2 0 0 1-2-2H5a3 3 0 0 0 3 3v-1ZM6 8a2 2 0 0 1 2-2V5a3 3 0 0 0-3 3h1Zm2-2a2 2 0 0 1 2 2h1a3 3 0 0 0-3-3v1Z"/></svg>`
};
export const iconHome: {
  name: 'home';
  data: string;
} = {
  name: 'home',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.09 9.441c.384 0 .696.312.696.697V20.41h3.482v-5.223a1.741 1.741 0 0 1 1.741-1.741h3.482a1.74 1.74 0 0 1 1.741 1.74v5.224h3.482V10.138a.696.696 0 0 1 1.393 0V20.41a1.393 1.393 0 0 1-1.393 1.393h-4.178a.696.696 0 0 1-.697-.697v-5.92a.348.348 0 0 0-.348-.348H10.01a.348.348 0 0 0-.348.349v5.92a.696.696 0 0 1-.697.696H4.786a1.393 1.393 0 0 1-1.393-1.393V10.138c0-.385.312-.697.696-.697Z"/><path d="m12.715 2.68 9.267 8.87a.696.696 0 0 1-.964 1.006l-9.254-8.859a.16.16 0 0 0-.028 0l-9.254 8.859a.696.696 0 0 1-.964-1.006l9.268-8.871c.284-.288.67-.376.967-.375.294 0 .68.089.962.375Z"/></svg>`
};
export const iconLogout: {
  name: 'logout';
  data: string;
} = {
  name: 'logout',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.325 16.275a1.11 1.11 0 0 1-.275-.737c0-.275.092-.504.275-.688l1.85-1.85H10a.968.968 0 0 1-.713-.288A.964.964 0 0 1 9 12c0-.283.096-.521.288-.713A.964.964 0 0 1 10 11h7.175l-1.85-1.85c-.2-.2-.3-.438-.3-.713 0-.275.1-.513.3-.712.183-.2.413-.3.688-.3a.93.93 0 0 1 .687.275l3.6 3.6c.1.1.171.208.213.325.042.117.063.242.062.375 0 .133-.02.258-.062.375a.865.865 0 0 1-.213.325l-3.6 3.6c-.217.217-.454.313-.713.288a1.046 1.046 0 0 1-.662-.313ZM5 21c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 0 1 3 19V5c0-.55.196-1.021.588-1.413A1.922 1.922 0 0 1 5 3h6c.283 0 .521.096.713.288.192.192.288.43.287.712a.968.968 0 0 1-.288.713A.964.964 0 0 1 11 5H5v14h6c.283 0 .521.096.713.288.192.192.288.43.287.712a.968.968 0 0 1-.288.713A.964.964 0 0 1 11 21H5Z"/></svg>`
};
export const iconMenuClose: {
  name: 'menu-close';
  data: string;
} = {
  name: 'menu-close',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M7.515 23.071a1 1 0 1 0 1.414 1.414L16 17.415l7.071 7.07a1 1 0 0 0 1.414-1.414L17.415 16l7.07-7.071a1 1 0 0 0-1.414-1.414L16 14.585l-7.071-7.07a1 1 0 1 0-1.414 1.414L14.585 16l-7.07 7.071Z"/></svg>`
};
export const iconMenu: {
  name: 'menu';
  data: string;
} = {
  name: 'menu',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M4 10a1 1 0 0 1 1-1h22a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm0 6a1 1 0 0 1 1-1h22a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm0 6a1 1 0 0 1 1-1h22a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Z"/></svg>`
};
export const iconMinus: {
  name: 'minus';
  data: string;
} = {
  name: 'minus',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8Z"/></svg>`
};
export const iconOutbreak: {
  name: 'outbreak';
  data: string;
} = {
  name: 'outbreak',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M10.65 14.75H4.57c-.98 0-1.52 0-2.03-.26-.44-.23-.8-.58-1.03-1.03-.26-.5-.26-1.04-.26-2.03V5.35c0-.98 0-1.52.26-2.03.23-.44.58-.8 1.03-1.03.5-.26 1.04-.26 2.03-.26h1.44c.41 0 .75.34.75.75s-.34.75-.75.75H4.57c-.7 0-1.16 0-1.35.09-.16.08-.29.21-.37.37-.09.18-.09.65-.09 1.35v6.08c0 .7 0 1.16.09 1.35.08.16.21.29.37.37.18.09.64.09 1.34.09h6.08c.7 0 1.16 0 1.34-.09.16-.08.29-.21.37-.37.09-.18.09-.62.09-1.34V9.99c0-.41.34-.75.75-.75s.75.34.75.75v1.44c0 .98 0 1.52-.26 2.02-.23.45-.58.8-1.03 1.03-.5.26-1.04.26-2.02.26l.02.01Zm-2.24-6.4c-.19 0-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l4.32-4.32h-2.19c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4a.776.776 0 0 1 .53.22c.07.07.13.15.16.24.04.09.06.19.06.29v4c0 .41-.34.75-.75.75s-.75-.34-.75-.75V3.81L8.94 8.13c-.15.15-.34.22-.53.22Z"/></svg>`
};
export const iconPlus: {
  name: 'plus';
  data: string;
} = {
  name: 'plus',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 4.5a.75.75 0 0 1 .75.75v6h6a.75.75 0 0 1 0 1.5h-6v6a.75.75 0 0 1-1.5 0v-6h-6a.75.75 0 0 1 0-1.5h6v-6A.75.75 0 0 1 12 4.5Z"/></svg>`
};
export const iconTechnical: {
  name: 'technical';
  data: string;
} = {
  name: 'technical',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.7 3.209c1.69-1.677 4.367-2.177 6.501-1.262.173.091.173.091.31.237a.79.79 0 0 1 .16.351c.02.196.02.196-.026.391-.075.18-.075.18-.194.317l-2.818 2.82a.754.754 0 0 0-.001 1.062l1.243 1.244a.755.755 0 0 0 1.064 0l2.836-2.839c.141-.12.141-.12.314-.19.185-.045.185-.045.373-.03a.82.82 0 0 1 .344.144.803.803 0 0 1 .245.294c.928 1.907.412 4.884-1.26 6.53-1.506 1.486-3.754 1.813-6.098 1.185a.746.746 0 0 0-.737.212l-7.202 7.817a3.008 3.008 0 1 1-4.255-4.239l7.806-7.129a.747.747 0 0 0 .212-.75c-.665-2.367-.342-4.65 1.184-6.165Zm1.058 1.064c-1.084 1.076-1.329 2.804-.797 4.695a2.246 2.246 0 0 1-.64 2.26l-7.818 7.14a1.507 1.507 0 1 0 2.138 2.118l7.214-7.83a2.246 2.246 0 0 1 2.225-.642c1.878.503 3.585.255 4.657-.803.898-.885 1.349-2.363 1.263-3.607l-.016-.161-1.986 1.988a2.257 2.257 0 0 1-3.182 0L14.57 8.184a2.254 2.254 0 0 1 .002-3.18l1.968-1.97c-1.347-.178-2.8.266-3.782 1.24Z"/><path d="M4.505 20.244a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"/></svg>`
};
export const iconUpload: {
  name: 'upload';
  data: string;
} = {
  name: 'upload',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.646 1.146a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1-.708.708L8.5 2.707v7.324a.5.5 0 1 1-1 0V2.707L5.854 4.354a.5.5 0 1 1-.708-.708l2.5-2.5ZM4.25 6.5a.75.75 0 0 0-.75.75v6a.75.75 0 0 0 .75.75h7.5a.75.75 0 0 0 .75-.75v-6a.75.75 0 0 0-.75-.75H10.5a.5.5 0 0 1 0-1h1.25a1.75 1.75 0 0 1 1.75 1.75v6A1.75 1.75 0 0 1 11.75 15h-7.5a1.75 1.75 0 0 1-1.75-1.75v-6A1.75 1.75 0 0 1 4.25 5.5H5.5a.5.5 0 0 1 0 1H4.25Z"/></svg>`
};
export const iconUser: {
  name: 'user';
  data: string;
} = {
  name: 'user',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM7 7a5 5 0 1 1 10 0A5 5 0 0 1 7 7Zm3 9a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3h-4Z"/></svg>`
};
export const iconVisibilityOff: {
  name: 'visibility-off';
  data: string;
} = {
  name: 'visibility-off',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.833 4.431a6.958 6.958 0 0 1 6.281 3.917 6.862 6.862 0 0 1-1.716 2.222l1.004 1.004a8.414 8.414 0 0 0 2.265-3.226c-1.232-3.126-4.273-5.341-7.833-5.341-.904 0-1.773.142-2.592.406l1.175 1.175a7.15 7.15 0 0 1 1.416-.157zm-.762.812 1.474 1.474c.406.178.733.506.912.912l1.474 1.474c.057-.242.1-.498.1-.762a3.192 3.192 0 0 0-3.197-3.197 2.99 2.99 0 0 0-.763.099zM.719 2.914l1.908 1.908A8.366 8.366 0 0 0 0 8.348c1.232 3.126 4.273 5.341 7.833 5.341a8.36 8.36 0 0 0 3.076-.584l2.435 2.435 1.004-1.004L1.723 1.903.719 2.914zM6.06 8.255l1.859 1.859a.36.36 0 0 1-.085.014 1.78 1.78 0 0 1-1.78-1.78c-.001-.036.006-.057.006-.093zM3.639 5.834 4.885 7.08a3.274 3.274 0 0 0-.256 1.268 3.21 3.21 0 0 0 4.465 2.949l.698.698a7.428 7.428 0 0 1-1.958.271 6.958 6.958 0 0 1-6.281-3.917 7.03 7.03 0 0 1 2.086-2.515z"/></svg>`
};
export const iconVisibilityOn: {
  name: 'visibility-on';
  data: string;
} = {
  name: 'visibility-on',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M7.833 4.424a6.958 6.958 0 0 1 6.281 3.917c-1.175 2.4-3.582 3.917-6.281 3.917s-5.106-1.517-6.281-3.917a6.958 6.958 0 0 1 6.281-3.917m0-1.424C4.273 3 1.232 5.215 0 8.341c1.232 3.126 4.273 5.341 7.833 5.341s6.601-2.215 7.833-5.341C14.435 5.215 11.394 3 7.833 3zm0 3.561a1.78 1.78 0 1 1-.001 3.561 1.78 1.78 0 0 1 .001-3.561m0-1.425c-1.766 0-3.205 1.438-3.205 3.205s1.438 3.205 3.205 3.205 3.205-1.438 3.205-3.205-1.439-3.205-3.205-3.205z"/></svg>`
};
export type WhbabtecIcon =
  | 'add-file'
  | 'add'
  | 'administrative'
  | 'arrow-right'
  | 'attention'
  | 'chevron-down'
  | 'chevron-right'
  | 'eye'
  | 'home'
  | 'logout'
  | 'menu-close'
  | 'menu'
  | 'minus'
  | 'outbreak'
  | 'plus'
  | 'technical'
  | 'upload'
  | 'user'
  | 'visibility-off'
  | 'visibility-on';
export interface WhbabtecIconInterface {
  name: WhbabtecIcon;
  data: string;
}
export type WhbabtecIconInterfaceNameSubset<
  T extends Readonly<WhbabtecIconInterface[]>
> = T[number]['name'];
export const completeIconSet = [
  iconAddFile,
  iconAdd,
  iconAdministrative,
  iconArrowRight,
  iconAttention,
  iconChevronDown,
  iconChevronRight,
  iconEye,
  iconHome,
  iconLogout,
  iconMenuClose,
  iconMenu,
  iconMinus,
  iconOutbreak,
  iconPlus,
  iconTechnical,
  iconUpload,
  iconUser,
  iconVisibilityOff,
  iconVisibilityOn
];
