import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@whbabtec-services';
import {
  WHBABTEC_DEFAULT_LANG,
  WHBABTEC_ENDPOINTS,
  WHBABTEC_STORAGE,
  WhbabtecAccessToken,
  WhbabtecLanguages,
  WhbabtecRefreshToken,
  WhbabtecTokensResponse,
  WhbabtecUser,
} from '@whbabtec-types';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user$$ = new BehaviorSubject<WhbabtecUser | undefined>(undefined);

  constructor(
    private storageService: StorageService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.getUserData().then((user) => this.user$$.next(user));
  }

  get user$(): Observable<WhbabtecUser | undefined> {
    return this.user$$.asObservable();
  }

  public isLoggedIn(): boolean {
    const accessToken = this.getAccessToken();

    return (
      accessToken !== undefined &&
      this.getRefreshToken() !== undefined &&
      !this.isTokenExpired(accessToken)
    );
  }

  public async login(
    username: string,
    password: string,
    keepLoggedIn: boolean
  ): Promise<void> {
    try {
      const response: WhbabtecTokensResponse = await firstValueFrom(
        this.httpClient.post<WhbabtecTokensResponse>(
          WHBABTEC_ENDPOINTS.AUTHENTICATE,
          {
            username,
            password,
          }
        )
      );

      this.storageService.set(WHBABTEC_STORAGE.KEEP_LOGGED_IN, keepLoggedIn);
      this.setTokens(response);

      this.getUserData().then((user) => {
        this.user$$.next(user);
        this.router.navigate([`/${this.getLanguage()}/complaints`]);
      });
    } catch (error: any) {
      this.clearTokens();
      this.clearUserData();
      return Promise.reject({ code: error.status });
    }
  }

  public async refreshLogin(): Promise<boolean> {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();

    if (
      !accessToken ||
      !refreshToken ||
      !this.storageService.get(WHBABTEC_STORAGE.KEEP_LOGGED_IN)
    ) {
      return false;
    }

    if (this.isTokenExpired(accessToken)) {
      try {
        const response: WhbabtecTokensResponse = await firstValueFrom(
          this.httpClient.post<WhbabtecTokensResponse>(
            WHBABTEC_ENDPOINTS.REFRESH,
            {
              accessToken,
              refreshToken,
            }
          )
        );

        this.setTokens(response);

        const user = await this.getUserData();
        this.user$$.next(user);

        return true;
      } catch (error) {
        return false;
      }
    }

    return false;
  }

  public logout(): void {
    this.clearTokens();
    this.clearUserData();
    this.router.navigate([`/${this.getLanguage()}/login`]);
  }

  public getLanguage(): WhbabtecLanguages {
    return (
      this.storageService.get<WhbabtecLanguages>(WHBABTEC_STORAGE.LANGUAGE) ||
      WHBABTEC_DEFAULT_LANG
    );
  }

  private async getUserData(): Promise<WhbabtecUser | undefined> {
    if (!this.isLoggedIn()) {
      return undefined;
    }

    const user = await this.httpClient.get<WhbabtecUser>(
      WHBABTEC_ENDPOINTS.USER_DATA,
      {
        headers: {
          Authorization: `Bearer ${this.getAccessToken()?.token}`,
        },
      }
    );

    return firstValueFrom(user);
  }

  public getAccessToken(): WhbabtecAccessToken | undefined {
    return this.storageService.get<WhbabtecAccessToken>(
      WHBABTEC_STORAGE.ACCESS_TOKEN
    );
  }

  private getRefreshToken(): WhbabtecRefreshToken | undefined {
    return this.storageService.get<WhbabtecRefreshToken>(
      WHBABTEC_STORAGE.REFRESH_TOKEN
    );
  }

  private setTokens(tokens: WhbabtecTokensResponse): void {
    this.storageService.set(WHBABTEC_STORAGE.ACCESS_TOKEN, tokens.accessToken);
    this.storageService.set(
      WHBABTEC_STORAGE.REFRESH_TOKEN,
      tokens.refreshToken
    );
  }

  private clearTokens(): void {
    this.storageService.remove(WHBABTEC_STORAGE.ACCESS_TOKEN);
    this.storageService.remove(WHBABTEC_STORAGE.REFRESH_TOKEN);
  }

  private clearUserData(): void {
    this.storageService.remove(WHBABTEC_STORAGE.KEEP_LOGGED_IN);
    this.user$$.next(undefined);
  }

  private isTokenExpired(token: WhbabtecAccessToken): boolean {
    const nowTimestamp = Date.now();
    return token.expiresIn - nowTimestamp < 0;
  }
}
