const BASE_URL = 'https://pnapi.wh.com';

export const WHBABTEC_DEBOUNCE_TIME = 300;

export type WhbabtecEndpoints = {
  AUTHENTICATE: string;
  REFRESH: string;
  USER_DATA: string;
  AUTOCOMPLTE_REF: string;
  PRODUCT: string;
  COMPLAINT_CREATE: string;
  COMPLAINT_OVERVIEW: string;
  FILE: string;
};

export const WHBABTEC_ENDPOINTS: WhbabtecEndpoints = {
  AUTHENTICATE: `${BASE_URL}/authentication/authenticate`,
  REFRESH: `${BASE_URL}/refresh`,
  USER_DATA: `${BASE_URL}/userdata`,
  AUTOCOMPLTE_REF: `${BASE_URL}/autocomplete`,
  PRODUCT: `${BASE_URL}/product`,
  COMPLAINT_CREATE: `${BASE_URL}/complaint`,
  COMPLAINT_OVERVIEW: `${BASE_URL}/complaint/status`,
  FILE: `${BASE_URL}/complaint/file`,
};
