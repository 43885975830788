import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanguageGuard } from '@whbabtec-guards';
import { WHBABTEC_DEFAULT_LANG } from './shared/types/languages.type';

const routes: Routes = [
  {
    path: '',
    redirectTo: `${WHBABTEC_DEFAULT_LANG}/login`,
    pathMatch: 'full',
  },
  {
    path: ':lang',
    loadChildren: () =>
      import('./wh-babtec/wh-babtec.module').then((m) => m.WhBabtecModule),
    canActivate: [LanguageGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
