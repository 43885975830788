export type WhbabtecLanguages =
  | 'de_global'
  | 'en_global'
  | 'es_global'
  | 'ru_cis';

export const WHBABTEC_LANGS: WhbabtecLanguages[] = [
  'de_global',
  'en_global',
  'es_global',
  'ru_cis',
];

export const WHBABTEC_DEFAULT_LANG: WhbabtecLanguages = 'en_global';
