import { Injectable, NgZone } from '@angular/core';
import {
  WHBABTEC_MAINTENANCE_MODE_ACTIVE,
  WhbabtecUiState,
} from '@whbabtec-types';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  private uiState$$ = new BehaviorSubject<WhbabtecUiState>({
    isContainerMax: false,
    isContentCentered: false,
    isLoading: false,
    isComplaintCreateMessageVisible: false,
    breadcrumbName: '',
    isMaintenanceModeActive: WHBABTEC_MAINTENANCE_MODE_ACTIVE,
  });

  constructor(private zone: NgZone) {}

  get uiState$(): Observable<WhbabtecUiState> {
    return this.uiState$$.asObservable();
  }

  public setState(delta: Partial<WhbabtecUiState>): void {
    this.zone.run(() => {
      const state = this.uiState$$.getValue();
      const newState = { ...state, ...delta };
      this.uiState$$.next(newState);
    });
  }
}
