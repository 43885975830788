import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WHBABTEC_MAINTENANCE_MODE_ACTIVE } from '@whbabtec-types';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private userService: UserService, private router: Router) {}

  public async canActivate(): Promise<boolean> {
    if (WHBABTEC_MAINTENANCE_MODE_ACTIVE) {
      this.router.navigate([`/${this.userService.getLanguage()}/maintenance`]);
      return false;
    }

    if (this.userService.isLoggedIn()) {
      return true;
    }

    const isAbleToRefreshLogin = await this.userService.refreshLogin();

    if (isAbleToRefreshLogin) {
      return true;
    } else {
      this.userService.logout();
    }

    return false;
  }
}
